.themeCard {
	height: 100%;
	display: grid;
	grid-template-columns: 100px auto 50px;
	box-shadow: var(--boxShadow);
	border-radius: 10px;
	overflow: hidden;
	max-width: 100%;
	gap: 20px;
	align-items: center;

	&-image {
		width: 100px;
	}

	&-infos {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}

	&-infosTitle {
		color: var(--brown);
		font-weight: 300;
		text-transform: uppercase;
		line-height: 1.5;
	}

	&-infosTitleLink {
		color: var(--brown);
		font-family: var(--oswald);
		letter-spacing: 1px;
		font-size: 14px;
	}

	&-infosText {
		display: none;
		color: var(--black);
		font-weight: 200;
		line-height: 1.5;
		text-align: center;
	}

	&-svg {
		width: 20px;
		height: 20px;
		margin: 0 auto;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--brown);
		}
	}
}

@media (--media-tablet) {
	.themeCard {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 20px;

		&-image {
			width: 100%;
		}

		&-infos {
			align-items: center;
			gap: 10px;
			padding: 0 10px;
		}

		&-infosTitleLink {
			font-weight: 400;
		}

		&-infosText {
			display: block;
		}

		&-svg {
			display: none;
		}
	}
}
