.hero {
	background-color: var(--darkGray);
	min-height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&::after {
		content: '';
		background-color: var(--trueBlack);
		opacity: 0.6;
		position: absolute;
		inset: 0;
	}

	&-container {
		z-index: 1;
		width: 100%;
		margin: 10px;
		padding: 20px;
		text-align: center;
	}

	&-containerTitle {
		color: var(--white);
		text-transform: uppercase;
		font-size: 38px;
		margin-bottom: 30px;
		font-weight: 700;
		line-height: 1.5;
		min-height: 138px;
		font-family: var(--oswald);
		letter-spacing: 4px;

		span {
			display: block;
		}
	}

	&-containerQuote {
		color: var(--brown);
		font-weight: 300;
		margin-bottom: 60px;
	}

	&-containerList {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	&-containerLinksListItem {
		button,
		a {
			font-family: var(--oswald);
			border: 2px solid var(--white);
			border-radius: 10px;
			display: block;
			text-decoration: none;
			color: var(--white);
			text-transform: uppercase;
			font-size: 12px;
			padding: 12px 35px;
			min-width: 170px;
			appearance: none;
			background-color: rgb(255 255 255 / 18%);
			letter-spacing: 1px;
			font-weight: 500;
			line-height: normal;

			&.orderButton {
				border: 2px solid var(--brown);
				background-color: var(--brown);
			}
		}
	}
}

@media (--media-desktop) {
	.hero {
		&::after {
			background: rgb(255 255 255);
			background: linear-gradient(0deg, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 100%) 80%);
			opacity: 0.7;
		}

		&-containerTitle {
			letter-spacing: 10px;
		}

		&-containerList {
			flex-direction: row;
			justify-content: center;
			align-items: stretch;
		}
	}
}
