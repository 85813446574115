.servicesCard {
	&-image {
		position: relative;
	}

	figure {
		&::before {
			content: '';
			position: absolute;
			inset: 0;
			background: linear-gradient(0deg, rgb(0 0 0 / 100%) 0%, rgb(9 9 121 / 0%) 100%);
			opacity: 0.5;
			z-index: 1;
		}
	}

	&-imageCaption {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		color: var(--white);
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	&-imageCaptionTitle {
		z-index: 2;
		text-align: center;
		line-height: 1.5;
		margin-bottom: 10px;
	}

	&-imageCaptionLink {
		color: var(--white);
		font-weight: 300;
		text-transform: uppercase;
		font-size: 25px;
		text-align: center;
		font-family: var(--oswald);
	}
}

@media (--media-tablet) {
	.servicesCard {
		&-imageCaption {
			align-items: center;
		}

		&-imageCaptionLink {
			font-size: 20px;
		}

		figure {
			&::before {
				background-color: var(--black);
				opacity: 0.5;
			}
		}

		&-imageCaptionTitle {
			font-weight: 200;
		}
	}
}
