.getintouch {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 10px 50px;
	gap: 30px;
	width: 100%;
	margin: 0 auto;
	position: relative;

	&::after {
		content: '';
		background-color: var(--black);
		opacity: 0.9;
		position: absolute;
		inset: 0;
		z-index: -1;
	}

	&-title {
		color: var(--brown);
		font-size: 35px;
		font-weight: 200;
		text-transform: uppercase;
		line-height: 1.5;
		font-family: var(--oswald);
		letter-spacing: 10px;
	}

	&-text {
		color: var(--white);
		font-size: 20px;
		font-weight: 200;
		font-style: italic;
		line-height: 1.5;
		text-align: center;
	}
}

@media (--media-desktop) {
	.getintouch {
		&-title {
			font-weight: 300;
		}

		&-text {
			width: 50%;
		}
	}
}
