.services {
	padding: 80px 10px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: var(--containerMaxWidth);
	margin: 0 auto;

	&-title {
		font-size: 26px;
		font-weight: 200;
		text-transform: uppercase;
		margin-bottom: 30px;
		font-family: var(--oswald);
		letter-spacing: 10px;
	}

	&-list {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
		margin-bottom: 50px;
	}

	&-listItem {
		overflow: hidden;
		border-radius: 10px;
	}

	&-link {
		font-family: var(--oswald);
		display: flex;
		align-items: center;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		color: var(--white);
		background-color: var(--brown);
		border: none;
		border-radius: 30px;
		padding: 12px 35px;
	}

	&-linkIcon {
		width: 10px;
		height: 10px;
		display: flex;
		margin-left: 10px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--white);
		}
	}
}

@media (--media-tablet) {
	.services {
		padding: 120px 10px 100px;

		&-title {
			font-weight: 300;
			margin-bottom: 90px;
		}

		&-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 20px;
		}
	}
}
