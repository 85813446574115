.discover {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 10px 100px;
	max-width: var(--containerMaxWidth);
	margin: 0 auto;

	&-image {
		aspect-ratio: 453/307;
		width: 100%;
		margin-bottom: 30px;
	}

	&-title {
		font-size: 26px;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 30px;
		font-family: var(--oswald);
		line-height: 1.5;
		letter-spacing: 10px;
		font-weight: 300;
	}

	&-text {
		font-weight: 200;
		line-height: 1.5;
		margin-bottom: 30px;
		text-align: center;

		strong {
			font-weight: 400;
		}
	}

	&-button {
		font-family: var(--oswald);
		display: flex;
		align-items: center;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		color: var(--white);
		background-color: var(--brown);
		border: none;
		border-radius: 30px;
		padding: 12px 35px;
	}

	&-buttonIcon {
		width: 10px;
		height: 10px;
		display: flex;
		margin-left: 10px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--white);
		}
	}
}

@media (--media-tablet) {
	.discover {
		display: grid;
		grid-template-areas:
			'image title'
			'image text'
			'image button';
		grid-template-columns: 0.7fr 1fr;
		gap: 0 40px;
		align-items: start;
		padding: 120px 0 100px;

		&-image {
			grid-area: image;
		}

		&-title,
		&-text {
			text-align: left;
		}

		&-button {
			grid-area: button;
			justify-self: start;
		}
	}
}

@media (--media-desktop) {
	.discover {
		gap: 0 100px;
	}
}
