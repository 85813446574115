.menu {
	padding: 80px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: var(--containerMaxWidth);
	margin: 0 auto;

	&-title {
		font-size: 26px;
		font-weight: 300;
		text-transform: uppercase;
		margin-bottom: 30px;
		font-family: var(--oswald);
		letter-spacing: 10px;
	}

	&-quote {
		font-size: 18px;
		color: var(--brown);
		font-weight: 200;
		font-style: italic;
		margin-bottom: 60px;
	}

	&-text {
		font-weight: 200;
		line-height: 1.5;
		margin-bottom: 50px;
		text-align: center;

		strong {
			font-weight: 600;
			float: none;
		}
	}

	&-list {
		background-color: var(--white);
		width: 100%;
		border-radius: 10px;
		margin-bottom: 60px;
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	&-link {
		font-family: var(--oswald);
		display: flex;
		align-items: center;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		color: var(--white);
		background-color: var(--brown);
		border-radius: 30px;
		padding: 12px 35px;
	}

	&-linkIcon {
		width: 10px;
		height: 10px;
		display: flex;
		margin-left: 10px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--white);
		}
	}
}

@media (--media-tablet) {
	.menu {
		&-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 20px;
		}
	}
}
