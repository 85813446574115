.reviewCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	&-image {
		width: 150px;
		height: 150px;
		overflow: hidden;
		border-radius: 50%;
	}

	&-stars {
		display: flex;
		height: 20px;
		gap: 5px;

		svg {
			width: 20px;
			height: 20px;
			fill: var(--gold);
		}
	}

	&-quote {
		width: 28px;
		height: 28px;

		svg {
			fill: var(--brown);
			width: 100%;
			height: 100%;
		}
	}

	&-name {
		font-size: 20px;
		font-weight: 400;
	}

	&-text {
		font-weight: 400;
		line-height: 1.5;
		font-style: italic;
		text-align: center;
	}
}
