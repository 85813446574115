.order {
	padding: 60px 10px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: var(--containerMaxWidth);
	width: 100%;
	margin: 0 auto;

	&-title {
		font-size: 26px;
		font-weight: 200;
		text-transform: uppercase;
		margin-bottom: 30px;
		font-family: var(--oswald);
		letter-spacing: 10px;
	}

	&-text {
		font-size: 18px;
		font-weight: 200;
		font-style: italic;
		margin-bottom: 30px;
		color: var(--brown);
	}

	&-list {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}

	&-listItem {
		box-shadow: var(--boxShadow);
		border-radius: 10px;
		padding: 10px;
		background-color: var(--white);
		display: grid;
		column-gap: 20px;
		grid-template-areas:
			'image text arrow'
			'image logo arrow';
		grid-template-columns: 100px auto 30px;
		/* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
		grid-template-rows: minmax(0, max-content) minmax(0, max-content);
	}

	&-listItemlogo {
		grid-area: logo;
		width: 100px;
		height: 20px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--brown);
		}
	}

	&-listItemImage {
		grid-area: image;
		overflow: hidden;
		border-radius: 10px;
	}

	&-listItemTitle {
		font-size: 25px;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 20px;
	}

	&-listItemLink {
		grid-area: text;
		color: var(--black);
		line-height: 1.5;
		margin-top: auto;
	}

	&-listItemSvg {
		grid-area: arrow;
		width: 20px;
		height: 20px;
		margin: auto;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--brown);
		}
	}

	&-strong {
		color: var(--brown);
		font-weight: 600;
		font-size: 18px;
	}

	&-weak {
		color: var(--brown);
		font-weight: 300;
		font-size: 12px;
	}
}

@media (--media-desktop) {
	.order {
		&-title {
			font-weight: 300;
		}

		&-text {
			margin-bottom: 60px;
		}

		&-list {
			flex-direction: row;
			justify-content: center;
			gap: 50px;
		}

		&-listItem {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			position: relative;
		}

		&-listItemLink {
			margin: 0;
		}

		&-listItemSvg {
			display: none;
		}
	}
}
