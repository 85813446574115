.reviews {
	padding: 80px 10px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: var(--containerMaxWidth);
	margin: 0 auto;

	&-carousel {
		width: 100%;
		height: 472px;
	}

	/* stylelint-disable selector-class-pattern */
	.swiper {
		width: 100%;
		padding: 0 0 50px;

		.swiper-pagination-bullet-active {
			background-color: var(--black);
		}

		.swiper-pagination-bullet {
			width: 10px;
			height: 10px;
		}

		.swiper-button-prev,
		.swiper-button-next {
			width: 30px;
			height: 30px;
			border-radius: 4px;
			padding: 7px;
			user-select: none;

			&::after {
				display: none;
			}

			svg {
				width: 100%;
				height: 100%;
				fill: var(--black);
			}
		}

		.swiper-button-prev {
			svg {
				transform: rotate(180deg);
			}
		}
	}
	/* stylelint-enable selector-class-pattern */

	&-title {
		font-size: 26px;
		font-weight: 200;
		text-transform: uppercase;
		margin-bottom: 70px;
		text-align: center;
		font-family: var(--oswald);
		letter-spacing: 10px;
		line-height: 1.5;
	}
}

@media (--media-desktop) {
	.reviews {
		padding: 120px 0 100px;

		&-title {
			font-weight: 300;
		}
	}
}
